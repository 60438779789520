import * as React from "react";

import KitsScreen from "../../../componets/KitsScreen";

const Decontamination = () => {
  return (
    <KitsScreen
      type="decontamination"
      modalOkLabel="Decontamination Complete"
    />
  );
};

export default Decontamination;
