import React from "react";
import logo from "../../assests/logo.jpg";
import metalogo from "../../assests/metaOs.webp";
import { Button, Form } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import TopBar from "../../componets/TopBarNew";
import PoweredBy from "../../componets/PoweredBy";

const Login = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/app/home");
  };

  return (
    <div
      className="d-flex align-items-center justify-content-between flex-column"
      style={{ height: "100vh" }}
    >
      <TopBar noLogout />
      <div>
        <h3 style={{ textAlign: "center" }} className="text-primary b-700">
          Central Sterile Services Department (CSSD)
        </h3>
        <div className="d-flex align-items-center justify-content-center">
          <div className="p-4 border round mt-3">
            <Form style={{ width: "20rem" }}>
              <h2 className="text-primary">Login</h2>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>
              <Button
                onClick={handleClick}
                className="w-100 bg-primary"
                type="submit"
              >
                Login
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <PoweredBy />
    </div>
  );
};

export default Login;
