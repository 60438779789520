import { notification } from "antd";

type NotificationType = "success" | "info" | "warning" | "error";

interface IParams {
  type: NotificationType;
  message: string;
  description?: string;
}

export const openNotification = ({
  type,
  message,
  description,
}: IParams) => {
  notification[type]({
    message,
    description,
  });
};
