import { IStatusType } from "../interfaces";

export type IActionKeys =
  | "inward"
  | "decontamination"
  | "sterilization"
  | "storage"
  | "outward";

export const statusCode = {
  inward: 1,
  decontamination: 2,
  sterilization: 3,
  storage: 4,
  sterilizationDone: 5,
  outwardRequested: 6,
  outward: 7,
};

export const statusCodeColor = {
  1: "red",
  2: "pink",
  3: "volcano",
  4: "gold",
  5: "green",
  6: "lime",
  7: "blue",
  8: "magenta",
};

export const statusCodelabels = {
  1: "Inward",
  2: "Decontamination",
  3: "Sterilization",
  4: "Sterilization In Progress",
  5: "In Store",
  6: "Outward Requested",
  7: "Ready to Pick Up",
};

export const notificationMap = (status: number) => {
  switch (status) {
    case statusCode.inward:
      return {
        success: "Inward Successful.",
        error: "Error in Inward.",
      };
    case statusCode.decontamination:
      return {
        success: "Decontamination Successful.",
        error: "Error in Decontamination.",
      };
    case statusCode.sterilization:
      return {
        success: "Sterilization Successful.",
        error: "Error in Sterilization.",
      };
    case statusCode.storage:
      return {
        success: "Sterilization In Progress",
        error: "Error in Updating Status.",
      };
    case statusCode.sterilizationDone:
      return {
        success: "Barcode Print Successfully",
        error: "Error in Updating Status.",
      };
    case statusCode.outwardRequested:
      return {
        success: "Outward Request Raised Successfully.",
        error: "Error in Updating Status.",
      };
    case statusCode.outward:
      return {
        success: "Outward Request Approved Successfully.",
        error: "Error in Updating Status.",
      };
  }
};

export const roomIds = {
  inward: 10,
  decontamination: 11,
  sterilization: 12,
  storage: 13,
  outward: 14,
};

export const roomToBeTriggered = {
  inward: roomIds.decontamination,
  decontamination: roomIds.sterilization,
  sterilization: roomIds.storage,
  storage: roomIds.outward,
  outward: roomIds.inward,
};

export const getCurrentStatus = (kitId: number, statuses: IStatusType[]) => {
  const statusObj = getStatusObject(statuses);
  return statusObj[kitId] || 4;
};

export const getCurrentStatusLabel = (
  kitId: number,
  statuses: IStatusType[]
) => {
  const statusObj = getStatusObject(statuses);
  return statusCodelabels[statusObj[kitId] || 4];
};

export const getCurrentStatusColor = (
  kitId: number,
  statuses: IStatusType[]
) => {
  const statusObj = getStatusObject(statuses);
  return statusCodeColor[statusObj[kitId] || 4];
};

export const filteringConditions = (
  action: IActionKeys,
  status: number = 4
) => {
  switch (action) {
    case "inward":
      return status === statusCode.outward;
    case "decontamination":
      return status === statusCode.inward;
    case "sterilization":
      return status === statusCode.decontamination;
    case "storage":
      return (
        status === statusCode.storage ||
        status === statusCode.sterilizationDone ||
        status === statusCode.outwardRequested
      );
    case "outward":
      return (
        status === statusCode.outward || status === statusCode.sterilizationDone
      );
  }
};

export const getStatusObject = (statuses: IStatusType[]) => {
  let obj = {};
  (statuses || []).map((s) => {
    if (obj[s.kit]) {
    } else {
      obj = { ...obj, [s.kit]: s.status };
    }
    return null;
  });
  return obj;
};

export const outwardPassword = "ABC123";

export const filterOptions = {
  storage: [
    {
      value: statusCode.storage,
      label: statusCodelabels[statusCode.storage],
    },
    {
      value: statusCode.sterilizationDone,
      label: statusCodelabels[statusCode.sterilizationDone],
    },
    {
      value: statusCode.outwardRequested,
      label: statusCodelabels[statusCode.outwardRequested],
    },
  ],
  outward: [
    {
      value: statusCode.outward,
      label: statusCodelabels[statusCode.outward],
    },
    {
      value: statusCode.sterilizationDone,
      label: statusCodelabels[statusCode.sterilizationDone],
    },
  ],
};
