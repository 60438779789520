import React from "react";

const useLoading = () => {
  const [loadingArray, setLoadingArray] = React.useState<string[]>([]);

  const startLoading = (loader: string = "") => {
    setLoadingArray((loadingArray) => [
      ...loadingArray.filter((x) => x !== loader),
      loader,
    ]);
  };

  const stopLoading = (loader: string = "") => {
    setLoadingArray((loadingArray) => loadingArray.filter((x) => x !== loader));
  };

  return {
    stopLoading,
    startLoading,
    loadingArray,
    loading: loadingArray.length > 0,
  };
};

export default useLoading;
