import React from "react";

import {
  ArrowRightOutlined,
  ClearOutlined,
  FireOutlined,
  BuildOutlined,
  ArrowLeftOutlined,
  ClusterOutlined,
  BookOutlined,
  PhoneOutlined,
  LogoutOutlined,
  HomeOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

import _ from "lodash";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Button, Breadcrumb, Layout, Menu, MenuProps } from "antd";

import logo from "../assests/logo.jpg";
import metaHOSlogo from "../assests/metaOs.webp";
import TopBar from "./TopBarNew";
import PoweredBy from "./PoweredBy";

const { Content, Sider } = Layout;

interface IProps {
  children: React.ReactNode;
}

const sideBarItems: (navigate: NavigateFunction) => MenuProps["items"] = (
  navigate
) => [
  {
    key: "home",
    icon: React.createElement(HomeOutlined),
    label: "Home",
    onClick: () => navigate("/app/home"),
  },
  {
    type: "divider",
  },
  {
    type: "divider",
  },
  {
    key: "inward",
    icon: React.createElement(ArrowRightOutlined),
    label: "Inward",
    onClick: () => navigate("/app/inward"),
  },
  {
    key: "decontamination",
    icon: React.createElement(ClearOutlined),
    label: "Decontamination",
    onClick: () => navigate("/app/decontamination"),
  },
  {
    key: "sterilization",
    icon: React.createElement(FireOutlined),
    label: "Sterilization",
    onClick: () => navigate("/app/sterilization"),
  },
  {
    key: "storage",
    icon: React.createElement(BuildOutlined),
    label: "Storage",
    onClick: () => navigate("/app/storage"),
  },
  {
    key: "outward",
    icon: React.createElement(ArrowLeftOutlined),
    label: "Outward",
    onClick: () => navigate("/app/outward"),
  },
  {
    type: "divider",
  },
  {
    type: "divider",
  },
  {
    key: "guide",
    icon: React.createElement(ClusterOutlined),
    label: "Guide",
    onClick: () => navigate("/app/guide"),
  },
  {
    key: "learn",
    icon: React.createElement(BookOutlined),
    label: "Learn",
    onClick: () => navigate("/app/learn"),
  },
  {
    key: "support",
    icon: React.createElement(PhoneOutlined),
    label: "Support",
    onClick: () => navigate("/app/support"),
  },
  {
    type: "divider",
  },
  {
    type: "divider",
  },
  {
    key: "reload",
    icon: React.createElement(ReloadOutlined),
    label: "Reload",
    onClick: () => window.location.reload(),
  },
  {
    key: "logout",
    icon: React.createElement(LogoutOutlined),
    label: "Logout",
    onClick: () => navigate("/"),
  },
];

const App: React.FC<IProps> = ({ children }) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const width = collapsed ? 80 : 200;
  const paths = window.location.pathname.split("/").filter((x) => !!x);
  const title = paths[paths.length - 1];
  const isHome = title === "home";

  return (
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      <Layout>
        {/* <Sider
          collapsed={collapsed}
          width={width}
          className="site-layout-background"
        >
          <div className="bg-white d-flex justify-content-center p-3">
            <img
              alt=""
              src={logo}
              style={{ width: collapsed ? "3rem" : "9rem" }}
            />
          </div>
          <Menu
            mode="inline"
            inlineCollapsed={!!collapsed}
            style={{ height: "100%", borderRight: 0 }}
            items={sideBarItems(navigate)}
          />

          <div
            className="bg-white d-flex justify-content-center p-3"
            style={{
              position: "fixed",
              bottom: 0,
              width,
            }}
          >
            <div>
              <span
                style={{
                  fontSize: collapsed ? "0.25.rem" : "0.5rem",
                  color: "#AAAAAA",
                }}
              >
                Powered By
              </span>
              <br />
              <img
                alt=""
                src={metaHOSlogo}
                style={{ width: collapsed ? "2rem" : "5rem" }}
              />
            </div>
          </div>
          <PoweredBy />

          <Button
            onClick={toggleCollapsed}
            style={{ position: "fixed", bottom: 10, left: width }}
            className="d-flex justify-content-center align-items-center"
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </Sider> */}
        <TopBar />
        <Layout style={{ padding: "0 24px 24px" }}>
          {!isHome && (
            <>
              <Breadcrumb style={{ margin: "16px 0" }}>
                {paths.map((location) => (
                  <Breadcrumb.Item>{_.startCase(location)}</Breadcrumb.Item>
                ))}
              </Breadcrumb>
              {/* <h1>{_.startCase(title || "")}</h1> */}
            </>
          )}
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
