import * as React from "react";
import { Row, Col, Space, Spin, Alert, Card } from "antd";

import useData from "./data";
import InwardModal from "../../componets/InwardModal";
import { IActionKeys } from "../../constants/roomIds";

const { Meta } = Card;

interface IProps {
  type: IActionKeys;
  modalOkLabel?: string;
  onOkay?: (kit: any) => void;
  hideFooter?: boolean;
  disableKitSelection?: boolean;
  onCancel?: (toggle: () => void) => void;
  onCardClick?: (kit: Record<string, any>) => void;
}

const KitsScreen = ({
  type,
  onCardClick,
  modalOkLabel,
  hideFooter,
  disableKitSelection,
  onOkay,
  onCancel,
}: IProps) => {
  const { state, setSelectedKit } = useData();

  const { kits, loading, selectedKit } = state;

  if (kits.length === 0 || loading) {
    return (
      <Row justify="center" align="middle">
        <Col
          sm={24}
          className={
            "d-flex flex-row align-items-center justify-content-center"
          }
        >
          <Spin spinning={loading}>
            {!loading && (
              <Alert message="Something Went Wront" type="warning" />
            )}
          </Spin>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={8}>
      {(kits || []).map((kit) => (
        <Col key={kit.id} md={6} className={"mb-2"}>
          <Card
            hoverable
            onClick={() => {
              if (onCardClick) {
                onCardClick(kit);
              } else {
                setSelectedKit(kit);
              }
            }}
          >
            <Meta title={kit.title} />
          </Card>
        </Col>
      ))}
      {selectedKit && (
        <InwardModal
          type={type}
          onOkay={onOkay}
          kit={selectedKit}
          onCancel={onCancel}
          isOpen={!!selectedKit}
          hideFooter={hideFooter}
          modalOkLabel={modalOkLabel}
          disableKitSelection={disableKitSelection}
          toggle={() => {
            setSelectedKit(null);
          }}
        />
      )}
    </Row>
  );
};

export default KitsScreen;
