import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home, InstrumentDectect, Login, MainApp, NotFound404 } from "./pages";

import OutWard from "./pages/App/Outward";
import Inward from "./pages/App/Inward/index";
import Storage from "./pages/App/Storage/index";
import Sterilization from "./pages/App/Sterilization";
import Decontamination from "./pages/App/Decontamination";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="app" element={<MainApp />}>
            <Route path="home" element={<Home />} />
            <Route path="inward" element={<Inward />} />
            <Route path="outward" element={<OutWard />} />
            <Route path="decontamination" element={<Decontamination />} />
            <Route path="storage" element={<Storage />} />
            <Route path="sterilization" element={<Sterilization />} />
            <Route path="*" element={<NotFound404 />} />
          </Route>
          <Route path="instrumentDectection" element={<InstrumentDectect />} />
          <Route path="*" element={<NotFound404 />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
