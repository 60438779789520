import React from "react";
import metaHOSlogo from "../assests/metahos.png";

const PoweredBy: React.FC = () => {
  return (
    <div className="mb-4">
      <span
        style={{
          fontSize: "0.5rem",
          color: "#AAAAAA",
        }}
      >
        Powered By
      </span>
      <br />
      <img alt="" src={metaHOSlogo} style={{ width: "5rem" }} />
    </div>
  );
};

export default PoweredBy;
