import React from "react";

import { LogoutOutlined, ReloadOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import pack from "../../package.json";
import logo from "../assests/logo.jpg";

interface IProps {
  noLogout?: boolean;
}

const TopBar: React.FC<IProps> = ({ noLogout }) => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-white d-flex justify-content-between align-items-center p-3"
      style={{ width: "100vw" }}
    >
      <img
        alt=""
        src={logo}
        style={{ width: "7rem" }}
        onClick={() => navigate("/app/home")}
      />
      <div className="d-flex justify-content-between align-items-center">
        <div style={{ color: "#CCCCCC" }}>
          <b>v{pack.version}</b>
        </div>
        <Button
          shape="round"
          type="text"
          icon={<ReloadOutlined />}
          onClick={() => window.location.reload()}
          className="d-flex justify-content-center align-items-center"
        >
          Reload
        </Button>
        {!noLogout && (
          <Button
            type="link"
            shape="round"
            icon={<LogoutOutlined />}
            onClick={() => navigate("/")}
            className="d-flex justify-content-center align-items-center"
          >
            Logout
          </Button>
        )}
      </div>
    </div>
  );
};

export default TopBar;
