import React from "react";
import WebcamMain from "react-webcam";
import { Select, Button } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";

const { Option } = Select;
const isFrontCamera = (device: MediaDeviceInfo) => {
  return (
    (device.label || "").includes("front") ||
    (device.label || "").includes("FaceTime HD")
  );
};

const Webcam: React.ForwardRefRenderFunction<{}> = (_, forwardedRef) => {
  const webcamRef = React.useRef<any>(null);
  const [cameraOn, setCameraOn] = React.useState<boolean>(true);
  const [selectedDevice, setSelectedDevice] =
    React.useState<MediaDeviceInfo | null>(null);
  const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([]);

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      const devices = mediaDevices.filter(({ kind }) => kind === "videoinput");
      setDevices(devices);

      const frontCameras = devices.filter(isFrontCamera);
      if (frontCameras.length > 0) {
        setSelectedDevice(frontCameras[0]);
      } else if (devices.length > 0) {
        setSelectedDevice(devices[0]);
      }
    },
    [setDevices, setSelectedDevice]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const toggleCamera = () => {
    setCameraOn(!cameraOn);
  };

  React.useImperativeHandle(forwardedRef, () => ({
    getScreenshot: () => {
      return webcamRef?.current.getScreenshot();
    },
    toggleCamera,
    isCameraOn: cameraOn,
    selectedDevice,
    devices,
  }));

  return (
    <>
      <div className="d-flex mb-2">
        {cameraOn && (
          <>
            <Select
              style={{ width: 200, display: "inline-block", marginRight: 10 }}
              value={selectedDevice?.deviceId}
              onChange={(deviceId) => {
                const device = devices.filter(
                  (device) => device.deviceId === deviceId
                );
                if (device.length === 1) {
                  setSelectedDevice(device[0]);
                }
              }}
            >
              {devices.map((device, key) => (
                <Option value={device.deviceId}>
                  {device.label || `Device ${key + 1}`}
                </Option>
              ))}
            </Select>
          </>
        )}

        <Button
          shape="round"
          type="primary"
          danger={cameraOn}
          icon={<VideoCameraOutlined className="mt-1" />}
          onClick={toggleCamera}
          className="d-flex"
        >
          {cameraOn ? "Turn Off" : "Turn On"}
        </Button>
      </div>

      {selectedDevice && cameraOn ? (
        <div>
          <WebcamMain
            ref={webcamRef}
            width={400}
            style={{ borderRadius: 5 }}
            mirrored={isFrontCamera(selectedDevice)}
            audio={false}
            videoConstraints={{ deviceId: selectedDevice.deviceId }}
          />
        </div>
      ) : (
        <div
          style={{ width: 400, height: 300, borderRadius: 5 }}
          className="bg-dark"
        >
          {" "}
        </div>
      )}
    </>
  );
};

export default React.forwardRef(Webcam);
