import React from "react";
import { Link } from "react-router-dom";
import inward from "../../assests/inward.jpg";
import decontamination from "../../assests/decontamination.jpg";
import Sterilization from "../../assests/serialization.jpg";
import logo from "../../assests/logo.jpg";
import Storage from "../../assests/storage.jpg";
import Outward from "../../assests/outward.jpg";
import { AiOutlineUser } from "react-icons/ai";
import metalogo from "../../assests/metaOs.webp";
import PoweredBy from "../../componets/PoweredBy";

const Button = ({ title, bg, to }) => {
  return (
    <Link to={to}>
      <div
        className="bg position-relative"
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.54)), url("${bg}")`,
          width: "13rem",
          height: "8rem",
          marginRight: "1rem",
          borderRadius: "1rem",
        }}
      >
        <span
          className="link-n position-absolute"
          style={{ bottom: "10%", left: "10%", fontSize: "1.1rem" }}
        >
          {title}
        </span>
      </div>
    </Link>
  );
};

const Home = () => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-between position-relative"
      style={{ width: "100wh", height: "89vh" }}
    >
      <div> </div>
      <div className="d-flex" style={{ marginTop: "-4rem" }}>
        <Button
          title="Inward"
          bg="https://res.cloudinary.com/teleopdassets/image/upload/v1667300170/cssd/outward.b38a66e624ead4cb00c6_nd0ppi.jpg"
          to="/app/inward"
        />
        <Button
          title="Decontamination"
          bg="https://res.cloudinary.com/teleopdassets/image/upload/v1667300099/cssd/decontamination.fae04f6c7bf0e4535d43_snej39.jpg"
          to="/app/decontamination"
        />
        <Button
          title="Sterilization"
          bg="https://res.cloudinary.com/teleopdassets/image/upload/v1667300119/cssd/serialization.e5643d58f199a93966ce_ysylfq.jpg"
          // to="/instrumentDectection"
          to="/app/sterilization"
        />
        <Button
          title="Storage"
          bg="https://res.cloudinary.com/teleopdassets/image/upload/v1667300139/cssd/storage.87305c14507d708457d3_ywemws.jpg"
          to="/app/storage"
        />
        <Button
          title="Outward"
          bg="https://res.cloudinary.com/teleopdassets/image/upload/v1667300170/cssd/outward.b38a66e624ead4cb00c6_nd0ppi.jpg"
          to="/app/outward"
        />
      </div>
      <PoweredBy />
    </div>
  );
};

export default Home;
