import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Button, Typography } from "antd";
import KeyBoard from "./KeyBoard";
import { ArrowLeftOutlined, EnterOutlined } from "@ant-design/icons";
import { outwardPassword } from "../../../constants/roomIds";
import { openNotification } from "../../../utils/notification";
import KitsScreen from "../../../componets/KitsScreen";

const { Paragraph } = Typography;

const OutWard = () => {
  const [pin, setPin] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  return authenticated ? (
    <KitsScreen type={"outward"} hideFooter disableKitSelection />
  ) : (
    <Container className="px-5 py-3 text-center">
      <Row>
        <Col>
          <Paragraph>
            {!pin && "___ - ___"}
            {pin.slice(0, 3)}{" "}
            {pin.length > 3 ? "- " + pin.slice(3, pin.length) : ""} {}
          </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col className="" sm={10}>
          <div>
            <KeyBoard
              disabled={pin.length > 5}
              isNumberKeyBoard={pin.length < 3}
              setPin={setPin}
              pin={pin}
            />
          </div>
        </Col>
        <Col sm={2}>
          <div className="py-3 d-flex flex-column">
            <Button
              className="bg-primary mb-3"
              style={{
                background: "tomato",
                color: "white",
                width: "10rem",
                height: "4rem",
              }}
              onClick={() => {
                if (
                  outwardPassword.toLocaleLowerCase() ===
                  (pin || "").toLocaleLowerCase()
                ) {
                  setAuthenticated(true);
                  openNotification({
                    type: "success",
                    message: "Pin validated Successfully",
                  });
                } else {
                  openNotification({
                    type: "error",
                    message: "Invalid Pin",
                  });
                }
              }}
            >
              <EnterOutlined /> Enter
            </Button>
            <Button
              style={{
                background: "tomato",
                color: "white",
                width: "10rem",
                height: "4rem",
              }}
              className={"mb-3"}
              onClick={() => setPin((p) => p.slice(0, -1))}
            >
              <ArrowLeftOutlined style={{ marginTop: "-1rem" }} />
            </Button>
            <Button
              className={"mb-3"}
              style={{
                background: "tomato",
                color: "white",
                width: "10rem",
                height: "4rem",
              }}
              onClick={() => setPin("")}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OutWard;
