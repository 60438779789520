import React, { useEffect, useState } from "react";
import { Button, Row, Col, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assests/logo.jpg";
import Webcam from "react-webcam";
import { AiOutlineUser } from "react-icons/ai";
import axios from "axios";
// const https = require('https');
import _ from "lodash";

// const list: string[] = [
//   "SUCTION (Poole Suction Tube)",
//   "SUCTION (Yankauer Suction Tube)",
//   "	RETRACTORS (Bookwalter Retractor)",
//   "	RETRACTORS (Richardson Retractor)",
//   "	RETRACTORS (Weitlaner Retractor)",
//   "	RETRACTORS (Army-Navy Retractor)",
//   "RETRACTORS (Deaver Retractor)",
//   "	NEEDLES & SUTURE (Skin Glue and Staplers)",
//   "	NEEDLES & SUTURE (Conventional Cutting Needle)",
//   "	NEEDLES & SUTURE (Tapered Needle)",
//   "	CLAMPS (Allis and Babcock Clamps)",
//   "CLAMPS (Kocher Clamp)",
//   "CLAMPS (Kelly Clamp)",
//   "CLAMPS (Crile Hemostat)",
//   "FORCEPS (Russian Forceps)",
//   "FORCEPS (DeBakey Forceps)",
//   "FORCEPS (Bonney Forceps)",
//   "FORCEPS (Adson Forceps)",
//   "FORCEPS (Tissue Forceps)",
//   "SCISSORS Iris",
//   "SCISSORS Pott’s",
//   "SCISSORS Metzenbaum",
//   "SCISSORS Mayo",
//   "SCALPEL #15",
//   "SCALPEL #11",
//   "SCALPEL #10",
// ];

const InstrumentDectect = () => {
  const [list, setList] = useState([]);
  useEffect(() => {}, []);

  const [selectedFile, setSelectedFile] = useState(null);
  // const [itemCheck, setItemCheck] = useState()

  const sendRequest = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append("file", imageFile);
      const response = await axios.post(
        "https://cdsimerproxy.metahos.com/predict_tools",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Proxy-Target": "https://cv.metahos.com:8080",
          },
        }
      );

      if (response.data.status === "failed") {
        return null;
      }

      setList((list) => {
        const filterItems = Object.keys(response.data.number).filter((key) => {
          return response.data.number[key] >= 30;
        });

        const transformedFilterItems = filterItems
          .map((item) => {
            const index = _.findIndex(list, { name: item, detected: true });
            if (index > -1) {
              if (list[index].confidence > 80) {
                return null;
              } else if (
                list[index].confidence < 80 &&
                response.data.number[item] > 80
              ) {
                return list.splice(index, 1);
              } else {
                return null;
              }
            }
            return {
              name: item,
              image: item,
              detected: true,
              checked: response.data.number[item] > 80 ? true : false,
              color:
                response.data.number[item] > 80 ? "green-secondary" : "info",
              confidence: response.data.number[item],
              textcolor: response.data.number[item] > 80 ? "white" : "black",
            };
          })
          .filter((item) => !!item);

        return [...transformedFilterItems, ...list];
      });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(()=>{console.log(list)}, [list])

  const uploadFile = async (event) => {
    event.preventDefault();
    sendRequest(selectedFile);
  };

  const handleSubmit = async (event) => {
    uploadFile(event);
  };

  const handleFileSelect = (event) => {
    console.log({
      EventTarget: event.target,
      input: event.target.input,
      value: event.target.value,
      files: event.target.files,
    });
    setSelectedFile(event.target.files[0]);
  };

  const [isShowVideo, setIsShowVideo] = useState(false);
  // const videoElement = React.useRef(null);

  const startCam = () => {
    setIsShowVideo(true);
  };

  const stopCam = () => {
    // let stream = videoElement.current.stream;
    // const tracks = stream.getTracks();
    // tracks.forEach(track => track.stop());
    setIsShowVideo(false);
  };

  const webcamRef = React.useRef(null);
  const [, setImages] = React.useState([]);
  const [on, setOn] = React.useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    const fileReader = new FileReader(); // api in browser to generate imageUrl

    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };

    fileReader.readAsDataURL(selectedFile); // once reading of file is done then above statement will run.
  }, [selectedFile]);

  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  const capture = React.useCallback(async () => {
    const newImage = webcamRef.current.getScreenshot();
    // console.log(newImage)
    // const blob = await fetch(newImage).then((res) => res.blob());
    // const formData = new FormData();
    // formData.append('file', blob)
    urltoFile(newImage, "faisal.jpeg").then((file) => {
      sendRequest(file);
    });
    // sendRequest(blob)
    setImages((images) => [...images, newImage]);
  }, [webcamRef, setImages]);

  React.useEffect(() => {
    if (on) {
      const i = setInterval(capture, 1000);
      return () => {
        clearInterval(i);
      };
    }
  }, [on]);

  return (
    <div>
      <div className="p-3 d-flex justify-content-between align-items-center">
        <Link to="/app/home">
          <img alt="" src={logo} style={{ width: "14rem" }} />
        </Link>
        <div className="d-flex justify-content-between align-items-center">
          <AiOutlineUser
            className="c-p"
            style={{ marginTop: "-1rem" }}
            size={"1.6rem"}
          />
          <p className="b-700 c-p ms-3">Log Out</p>
        </div>
      </div>
      <Row className="p-4 pt-0">
        <Col sm={8} className="p-3">
          <Row>
            <Col className="p-4" sm={6}>
              <div style={{}}>
                {/* <Webcam width={"100%"} height={"100%"} /> */}
                <h2>Webcam</h2>
                {isShowVideo && (
                  <Webcam
                    width={"100%"}
                    height={"100%"}
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                  />
                )}

                {/* {images.map((image) => (
              <img src={image} style={{ width: 20, height: 20, display: 'inline-block' }} alt=""/>
            ))} */}

                {/* <Button onClick={stopCam} className="me-2 px-5 bg-red-primary">Turn off</Button> */}
              </div>
              {/* <p className="b-700 mt-3">Date & Time: {new Date().toString()}</p>
              <p className="b-700">Admin Logged In</p> */}
            </Col>

            <Col className="p-4 my-5" sm={6}>
              <div>
                <Button
                  className="bg-green-secondary"
                  onClick={() => {
                    setOn(!on);
                  }}
                >
                  {on ? "Stop" : "Record"}
                </Button>
                <Button
                  onClick={isShowVideo ? stopCam : startCam}
                  className="me-2 mx-2 bg-red-primary"
                >
                  {isShowVideo ? "Turn Off" : "Turn On"}
                </Button>
              </div>
              <div className="">
                {/* <h6 className="mt-5">Choose File</h6> */}

                <div className="p-3">
                  {/* <Button className="me-2 px-5 bg-red-primary">No</Button>
                  <Button className="px-5 bg-green-secondary ">Yes</Button> */}
                  <form onSubmit={handleSubmit} className="d-flex mt-5">
                    <input
                      type="file"
                      onChange={handleFileSelect}
                      className="bg-blue-500"
                    />
                    {previewUrl && <input type="submit" value="Upload File" />}
                  </form>
                  {previewUrl && (
                    <div className="">
                      <img
                        className="mt-2"
                        style={{ height: "2rem", width: "2rem" }}
                        src={previewUrl}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          sm={4}
          className="p-3"
          style={{ height: "80vh", overflow: "scroll", overflowX: "hidden" }}
        >
          <ListGroup className="py-4">
            {list.map((item) => {
              console.log("====>", item.checked);
              return (
                <ListGroup.Item
                  key={item.id + Math.random()}
                  className={`d-flex mb-2 justify-content-between align-items-center  border text-white bg-${item.color}`}
                >
                  <div className="d-flex align-items-center">
                    <img
                      width={"60rem"}
                      height={"40rem"}
                      style={{ marginRight: "1em", objectFit: "cover" }}
                      src={`https://asset.metahos.com/${item.image}`}
                      alt=""
                    />
                    <p className={`text-${item.textcolor}`}>{item.name}</p>
                    {/* <p>{item.confidence}</p> */}
                  </div>
                  <input
                    type="checkbox"
                    defaultChecked={item.checked}
                    onChange={(e) => {
                      console.log(e.target.checked);
                    }}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};

export default InstrumentDectect;
