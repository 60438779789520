import React, { useState } from "react";
import axios from "axios";
import _ from "lodash";
import {
  Col,
  Modal,
  Row,
  Spin,
  List,
  Avatar,
  Button,
  Collapse,
  notification,
} from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import { HOST } from "../../../setting";
import Webcam from "../../../componets/Webcam";
import useLoading from "../../../hooks/useLoading";
import KitsScreen from "../../../componets/KitsScreen";
import { statusCode } from "../../../constants/roomIds";
import * as ItemListApi from "../../../services/items/list";
import * as CreateKitApi from "../../../services/kits/create";
import * as AssetsListApi from "../../../services/assets/list";

const Sterilization = () => {
  const webcamRef = React.useRef<any>(null);
  const [selectedKit, setSelectedKit] = React.useState<Record<
    string,
    any
  > | null>(null);

  const { loading, startLoading, stopLoading } = useLoading();
  const [items, setItems] = useState<Array<Record<string, any>>>([]);
  const [assets, setAssets] = useState<Array<Record<string, any>>>([]);
  const [kitDetail, setKitDetail] = useState<Record<string, any> | null>(null);
  const [batchNumber, setBatchNumber] = useState<string | null>(null);

  const [currentInView, setCurrentInView] = useState<Array<string>>([]);

  const isAdHoc = selectedKit?.title === "Ad-Hoc";

  React.useEffect(() => {
    startLoading("items");
    startLoading("assets");

    ItemListApi.service()
      .then(({ data }) => {
        setItems(data.items);
      })
      .catch(console.log)
      .finally(() => stopLoading("items"));

    AssetsListApi.service()
      .then(({ data }) => {
        setAssets(data.assets);
      })
      .catch(console.log)
      .finally(() => stopLoading("assets"));
  }, []);

  const findArrayById = (id: number, array) => {
    const index = _.findIndex(array, ["id", id]);
    if (index > -1) {
      return array[index];
    }

    return null;
  };

  const loadKitDetail = () => {
    if (!selectedKit) {
      return null;
    }

    startLoading("kit");
    axios
      .get(`${HOST}/master/kit-contents/${selectedKit.id}/`)
      .then(({ data }) => {
        setKitDetail(data);
        stopLoading("kit");
      })
      .catch(console.log)
      .finally(() => stopLoading("kit"));
  };

  const findCurrentInView = (imageFile: any) => {
    const formData = new FormData();
    formData.append("image", imageFile);

    axios
      .post("https://cssdai.metahos.com/process-json", formData)
      .then(({ data }) => {
        console.log(data);
        
        const inViews = Object.keys(data);
        const lowerMeds = inViews.map((p) => p.toLowerCase());
        setCurrentInView(inViews);
        console.log({
          inViews,
          lowerMeds,
          kitDetail,
        });
        if (kitDetail) {
          setKitDetail((kitDetail) => {
            if (!kitDetail) {
              return null;
            }

            return {
              ...kitDetail,
              kit_contents: (kitDetail.kit_contents || []).map((content) => {
                const asset = findArrayById(content.item, assets);
                console.log(asset);

                if (lowerMeds.includes(asset.name.toLowerCase())) {
                  if (!content.selected_quantity) {
                    return {
                      ...content,
                      selected_quantity: 1,
                    };
                  }
                }
                return content;
              }),
            };
          });
        }
      })
      .catch(console.log);
  };

  const findItemsInCamera = () => {
    if (webcamRef.current) {
      const image = webcamRef.current?.getScreenshot();
      if (image && webcamRef.current?.isCameraOn) {
        convertURLToFile(image, "img.jpeg", "image/jpeg")
          .then((file) => findCurrentInView(file))
          .catch(console.log);
      } else {
        setCurrentInView([]);
      }
    }
  };

  const convertURLToFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  React.useEffect(() => {
    const i = setInterval(findItemsInCamera, 1000);
    return () => {
      clearInterval(i);
    };
  }, [selectedKit, kitDetail]);

  React.useEffect(() => {
    loadKitDetail();
  }, [selectedKit]);

  const width = document.documentElement.clientWidth;
  const height = document.documentElement.clientHeight;

  const shouldButtonBeDisabled = () => {
    if (isAdHoc) {
      return false;
    }

    if (!selectedKit || !kitDetail) {
      return true;
    }

    const contents = (kitDetail.kit_contents || []).filter((content) => {
      const selected_quantity = content.selected_quantity || 0;
      return selected_quantity < content.quantity;
    });

    return contents.length > 0;
  };

  const close = () => {
    setSelectedKit(null);
    setKitDetail(null);
  };

  const createKit = () => {
    if (!selectedKit || !kitDetail) {
      return;
    }

    const contents = kitDetail.kit_contents || [];
    const data = {
      kit: selectedKit.id,
      count: contents.length,
    };

    for (let i = 0; i < contents.length; i++) {
      const index = i + 1;
      const content = contents[i];
      const selected_quantity = content.selected_quantity || 0;

      if (selected_quantity === 0) {
        continue;
      }

      data[`item_${index}`] = content.item;
      data[`quantity_${index}`] = content.selected_quantity || 0;
    }

    startLoading("create");
    CreateKitApi.service(data)
      .then(({ data }) => {
        notification.success({
          message: "Created Kit",
          description: `Successfully created kit with batch number: "${data.batch_number}"`,
        });
        close();
        setBatchNumber(data.batch_number);
      })
      .catch((e) => {
        console.log(e);
        notification.error({
          message: "Error in creating Kit",
        });
      })
      .finally(() => {
        stopLoading("create");
      });
  };

  const renderList = (contents, filter, small = false) => {
    return (
      <List
        size={small ? "small" : null}
        style={{ maxHeight: small ? "35vh" : "65vh", overflow: "scroll" }}
        dataSource={contents}
        renderItem={(content: any, index) => {
          if (!filter(content)) {
            return null;
          }
          const asset = findArrayById(content.item, assets);
          if (!asset) {
            return null;
          }

          const update = (addition) => {
            setKitDetail((kitDetail) => ({
              ...kitDetail,
              kit_contents: (kitDetail?.kit_contents || []).map(
                (content, i) => {
                  if (i === index) {
                    const update = Math.max(
                      (content.selected_quantity || 0) + addition,
                      0
                    );
                    return {
                      ...content,
                      selected_quantity: !isAdHoc
                        ? Math.min(update, content.quantity)
                        : update,
                    };
                  }

                  return content;
                }
              ),
            }));
          };

          const selectedQuantity = content.selected_quantity || 0;
          const isDone =
            selectedQuantity === content.quantity ||
            (isAdHoc && selectedQuantity > 0);

          return (
            <List.Item key={asset.id}>
              <div className="d-flex justify-content-center align-items-center">
                <Avatar
                  shape="square"
                  size={small ? 32 : 64}
                  src={`${HOST}${asset.image}`}
                />

                <div className={`${small ? "p-1" : "p-4"} pt-0 pb-0`}>
                  {small && (
                    <span className={isDone ? "text-success" : ""}>
                      {asset.name}
                    </span>
                  )}
                  {!small && (
                    <h3 className={isDone ? "text-success" : ""}>
                      {asset.name}
                    </h3>
                  )}
                  {!small && <span>{asset.description}</span>}
                </div>
              </div>

              <div
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: small ? 20 : 42 }}
              >
                <CaretDownOutlined
                  className={`p-0 pl-4 ${
                    selectedQuantity === 0 && !isAdHoc ? "text-muted" : ""
                  }`}
                  onClick={() => {
                    update(-1);
                  }}
                />
                <div
                  className={`${isDone ? "text-success" : "text-muted"} ${
                    small ? "p-1" : "p-4"
                  }`}
                >
                  {isAdHoc && <>{selectedQuantity}</>}
                  {!isAdHoc && (
                    <>
                      {selectedQuantity}/{content.quantity}
                    </>
                  )}
                </div>
                <CaretUpOutlined
                  className={`p-0 pr-4 ${
                    isDone && !isAdHoc ? "text-muted" : ""
                  }`}
                  onClick={() => {
                    update(1);
                  }}
                />
              </div>
            </List.Item>
          );
        }}
      />
    );
  };

  return (
    <>
      {batchNumber}
      <KitsScreen
        type="sterilization"
        statusCodeToFilter={statusCode.decontamination}
        onCardClick={setSelectedKit}
      />
      <Modal
        centered
        open={!!batchNumber}
        title="Kit Created Successfully"
        footer={
          <Button
            type="primary"
            shape="round"
            onClick={() => setBatchNumber(null)}
          >
            Ok
          </Button>
        }
      >
        <span className="text-muted">Please note down batch number</span>
        <h2>{batchNumber}</h2>
      </Modal>
      <Modal
        centered
        destroyOnClose
        width={width}
        bodyStyle={{ minHeight: height - 125 }}
        open={!!selectedKit}
        okText={shouldButtonBeDisabled() ? null : "Create New Kit"}
        onCancel={() => setSelectedKit(null)}
        title={`Create new "${selectedKit?.title}"`}
        okButtonProps={{ disabled: !selectedKit }}
        footer={
          <>
            <Button onClick={close} shape={"round"}>
              Cancel
            </Button>
            <Button
              type="primary"
              shape={"round"}
              disabled={shouldButtonBeDisabled()}
              onClick={createKit}
            >
              Create New Kit
            </Button>
          </>
        }
      >
        {selectedKit && (
          <Spin spinning={loading}>
            <Row>
              <Col md={8}>
                <div style={{ position: "relative" }}>
                  <Webcam ref={webcamRef} />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 10,
                      left: 10,
                      backgroundColor: "#000000AA",
                      color: "#FFFFFF",
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    {(currentInView || []).join(", ")}{" "}
                  </div>
                </div>

                {kitDetail &&
                  renderList(
                    kitDetail.kit_contents || [],
                    (content) =>
                      isAdHoc
                        ? (content.selected_quantity || 0) > 0
                        : content.selected_quantity === content.quantity,
                    true
                  )}
              </Col>
              <Col md={1}> </Col>
              <Col md={14}>
                {kitDetail && (
                  <>
                    {renderList(kitDetail.kit_contents || [], (content) =>
                      isAdHoc
                        ? true
                        : content.selected_quantity !== content.quantity
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Spin>
        )}
      </Modal>
    </>
  );
};

export default Sterilization;
