import axios from "axios";
import { HOST } from "../../setting";

import { tranformToFormData } from "../../utils/common";

interface IReq {
  kit: number;
  room?: number;
  rack?: number;
  shelf?: number;
  status?: number;
}

export const service = (data: IReq) => {
  return axios.post(`${HOST}/master/kit-status/`, tranformToFormData(data));
};
