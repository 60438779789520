import * as React from "react";

import * as GetKitList from "../../services/kits/getKits";

interface IState {
  loading: boolean;
  kits: Record<string, any>[];
  selectedKit: Record<string, any> | null;
  kitType: Array<{ id: string; title: string }>;
}

const useData = () => {
  const [loading, setLoading] = React.useState<IState["loading"]>(false);
  const [selectedKit, setSelectedKit] =
    React.useState<IState["selectedKit"]>(null);
  const [kits, setKits] = React.useState<IState["kits"]>([]);
  const [kitType, setKitType] = React.useState<IState["kitType"]>([]);

  const fetchGetKits = () => {
    setLoading(true);
    GetKitList.service()
      .then(({ data }) => {
        setKits(data.kits || []);
        setKitType(data.kit_types || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchGetKits();
  }, []);

  const state: IState = {
    kits,
    loading,
    kitType,
    selectedKit,
  };

  return { state, setLoading, setSelectedKit };
};

export default useData;
