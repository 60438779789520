import {
  filteringConditions,
  getCurrentStatus,
  notificationMap,
} from "./../../constants/roomIds";
import * as React from "react";
import _ from "lodash";

import { getStatusObject } from "../../constants/roomIds";
import { IActionKeys, roomToBeTriggered } from "../../constants/roomIds";
import { openNotification } from "../../utils/notification";
import * as GetDetail from "../../services/kits/kitBatchList";
import * as UpdateKitStatusApi from "../../services/kits/updateKitStatus";

interface IState {
  loading: boolean;
  updatingStatus: boolean;
  selectedKit: Record<string, any> | null;
  kitBatches: {
    kit: Record<string, any>;
    kit_list: Record<string, any>[];
    status: any;
  } | null;
  filterStatusCode: number | null;
}

interface IParams {
  kit: Record<string, any>;
  toggle: () => void;
  type: IActionKeys;
}

const useData = ({ kit, toggle, type }: IParams) => {
  const [filterStatusCode, setFilterStatusCode] =
    React.useState<IState["filterStatusCode"]>(null);
  const [loading, setLoading] = React.useState<IState["loading"]>(false);
  const [updatingStatus, setUpdatingStatus] =
    React.useState<IState["updatingStatus"]>(false);
  const [kitBatches, setKitBatches] =
    React.useState<IState["kitBatches"]>(null);
  const [selectedKit, setSelectedKit] =
    React.useState<IState["selectedKit"]>(null);

  const updateKitStatus = ({
    statusCode,
    kit: kitProp,
    callback,
  }: { statusCode?: number; kit?: any; callback?: () => void } = {}) => {
    const statusToBeUpdated = statusCode
      ? statusCode
      : getCurrentStatus(
          kitProp?.id || selectedKit?.id,
          kitBatches?.status || []
        ) + 1;
    const notificationObj = notificationMap(
      statusToBeUpdated > 7 ? 1 : statusToBeUpdated
    );
    if (kitProp || selectedKit) {
      setUpdatingStatus(true);
      UpdateKitStatusApi.service({
        kit: kitProp?.id || selectedKit?.id,
        status: statusToBeUpdated > 7 ? 1 : statusToBeUpdated,
        room: roomToBeTriggered[type],
      })
        .then(() => {
          openNotification({
            type: "success",
            message: "Success",
            description: notificationObj?.success,
          });
          callback && callback();
          toggle();
          setUpdatingStatus(false);
        })
        .catch(() => {
          toggle();
          openNotification({
            type: "error",
            message: "Something went wrong",
            description: notificationObj?.error,
          });
          setUpdatingStatus(false);
        });
    }
  };

  const fetchGetKits = () => {
    setLoading(true);
    console.log("called", kit);
    GetDetail.service(kit.id)
      .then(({ data }) => {
        setLoading(false);
        const statusObject = getStatusObject(data.status);
        console.log(statusObject, data.kit_list, type, "log");
        setKitBatches({
          ...data,
          kit_list: (data.kit_list || []).filter(
            (i: { id: string | number }) => {
              const d = filteringConditions(type, statusObject[i.id]);
              console.log(i.id, statusObject[i.id], type, d);
              return d;
            }
          ),
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (kit) {
      fetchGetKits();
    }
  }, []);

  const state: IState = {
    loading,
    kitBatches,
    selectedKit,
    updatingStatus,
    filterStatusCode,
  };

  return {
    state,
    setLoading,
    setSelectedKit,
    updateKitStatus,
    setFilterStatusCode,
  };
};

export default useData;
