import * as React from "react";
import moment from "moment";
import _ from "lodash";
import {
  Card,
  Col,
  Modal,
  Row,
  Tag,
  Spin,
  Button,
  Typography,
  Radio,
} from "antd";

import { printChecklist, printQRCode } from "../../utils/print";
import useData from "./data";
import {
  filterOptions,
  getCurrentStatus,
  getCurrentStatusColor,
  getCurrentStatusLabel,
  IActionKeys,
  statusCode,
} from "../../constants/roomIds";

const { Title, Paragraph } = Typography;

interface IProps {
  isOpen: boolean;
  type: IActionKeys;
  toggle: () => void;
  hideFooter?: boolean;
  disableKitSelection?: boolean;
  modalOkLabel?: string;
  kit: Record<string, any>;
  onOkay?: (kit?: any) => void;
  onCancel?: (toggle: () => void) => void;
}

const { Meta } = Card;

const InwardModal: React.FC<IProps> = ({
  isOpen,
  toggle,
  kit: outerKit,
  type,
  onOkay,
  hideFooter,
  modalOkLabel,
  disableKitSelection,
  onCancel,
}) => {
  const { state, setSelectedKit, updateKitStatus, setFilterStatusCode } =
    useData({
      kit: outerKit,
      toggle,
      type,
    });
  const { kitBatches, loading, selectedKit, updatingStatus, filterStatusCode } =
    state;

  const width = document.documentElement.clientWidth;
  const height = document.documentElement.clientHeight;

  return (
    <Modal
      centered
      width={width}
      bodyStyle={{ minHeight: height - 125 }}
      title={outerKit.title}
      open={isOpen}
      footer={hideFooter ? null : undefined}
      cancelButtonProps={{ shape: "round" }}
      okButtonProps={{
        disabled: !selectedKit,
        loading: updatingStatus,
        shape: "round",
      }}
      okText={modalOkLabel || _.startCase(type)}
      onOk={() => {
        if (onOkay) {
          onOkay(selectedKit);
        } else {
          updateKitStatus();
        }
      }}
      onCancel={() => {
        if (onCancel) {
          onCancel(toggle);
        } else {
          toggle();
        }
      }}
    >
      {Object.keys(filterOptions).includes(type) && (
        <Row>
          <Col className="mb-2">
            <Radio.Group
              value={filterStatusCode}
              onChange={(v: any) => {
                console.log(v, v.target.value, "vvv");
                setFilterStatusCode(v.target.value);
              }}
            >
              <Radio.Button
                type={filterStatusCode === null ? "primary" : undefined}
                value={null}
              >
                Show All
              </Radio.Button>
              {filterOptions[type].map((o) => (
                <Radio.Button
                  type={filterStatusCode === null ? "primary" : undefined}
                  value={o.value}
                >
                  {o.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>
        </Row>
      )}

      <Row gutter={8}>
        <Col sm={24}>
          {kitBatches?.kit_list.length === 0 && (
            <Spin spinning={loading}>
              <Title level={4} style={{ textAlign: "center" }}>
                No Record Found
              </Title>
            </Spin>
          )}
        </Col>
        {kitBatches?.kit_list.map((kit) => {
          const currentStatus = getCurrentStatus(kit.id, kitBatches.status);
          return (filterStatusCode && filterStatusCode === currentStatus) ||
            !filterStatusCode ? (
            <>
              <Col key={kit.id} sm={24} md={8} className={"mb-2"}>
                <Card
                  {...(kit.id === selectedKit?.id && {
                    headStyle: {
                      borderColor: "#00adff",
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderBottomWidth: 0,
                    },
                    bodyStyle: {
                      backgroundColor: "#e6f7ff",
                      borderColor: "#00adff",
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderTopWidth: 0,
                    },
                  })}
                  hoverable
                  onClick={() => {
                    if (!disableKitSelection) {
                      setSelectedKit(kit);
                    }
                  }}
                  title={kit.batch_number}
                  extra={
                    <Tag
                      color={getCurrentStatusColor(kit.id, kitBatches.status)}
                    >
                      {getCurrentStatusLabel(kit.id, kitBatches.status)}
                    </Tag>
                  }
                >
                  <Meta
                    description={
                      <Row>
                        <Col md={24}>
                          <Paragraph>{kit.current_number}</Paragraph>
                          <Paragraph>
                            Expires on {moment(kit.expiry_date).format("lll")}
                          </Paragraph>
                        </Col>

                        <div className="d-flex flex-wrap" style={{ gap: 12 }}>
                          {currentStatus === statusCode.sterilizationDone &&
                            type === "outward" && (
                              <Button
                                shape="round"
                                type="primary"
                                onClick={() => {
                                  updateKitStatus({ kit });
                                }}
                              >
                                Request Kit
                              </Button>
                            )}
                          {currentStatus === statusCode.outwardRequested && (
                            <>
                              <Button
                                shape="round"
                                type="primary"
                                className="pr-1"
                                onClick={() => {
                                  updateKitStatus({
                                    kit,
                                  });
                                }}
                              >
                                Approve Request
                              </Button>
                              <Button
                                shape="round"
                                className="pl-1"
                                onClick={() => {
                                  updateKitStatus({
                                    kit,
                                    statusCode: statusCode.sterilizationDone,
                                  });
                                }}
                              >
                                Cancel Request
                              </Button>
                            </>
                          )}
                          {currentStatus >= statusCode.storage && (
                            <>
                              <Button
                                shape="round"
                                onClick={() => {
                                  if (currentStatus === statusCode.storage) {
                                    updateKitStatus({
                                      kit,
                                      callback: () => {
                                        printQRCode(
                                          {
                                            id:kit.id,
                                            title: outerKit.title,
                                            cycleNumber:kit.current_number,
                                            expiryDate: moment(kit.expiry_date).format("DD-MM-YYYY"),
                                            batchNumber: kit.batch_number,
                                            processDate: moment(kit.date).format("DD-MM-YYYY"),
                                            callback:() => {}
                                          } 
                                           ).then(
                                          () => {}
                                        );
                                      },
                                    });
                                  } else {
                                    printQRCode({
                                      id:kit.id,
                                      title: outerKit.title,
                                      cycleNumber:kit.current_number,
                                      expiryDate: moment(kit.expiry_date).format("DD-MM-YYYY"),
                                      batchNumber: kit.batch_number,
                                      processDate: moment(kit.date).format("DD-MM-YYYY"),
                                      callback:() => {}
                                    }).then(
                                      () => {}
                                    );
                                  }
                                }}
                              >
                                Print QR
                              </Button>
                              <Button
                                shape="round"
                                onClick={() => {
                                  printChecklist({ kit, outerKit }, () => {});
                                }}
                              >
                                Print Checklist
                              </Button>
                            </>
                          )}
                        </div>
                      </Row>
                    }
                  />
                </Card>
              </Col>
            </>
          ) : null;
        })}
      </Row>
    </Modal>
  );
};

export default InwardModal;
