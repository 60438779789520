import axios from "axios";
import _ from "lodash";

import { HOST } from "../setting";
import * as AssetsListApi from "../services/assets/list";

export const getKitContent = async (kitId) => {
  const [
    {
      data: { assets },
    },
    { data: kitDetail },
  ] = await Promise.all([
    await AssetsListApi.service(),
    await axios.get(`${HOST}/master/kit-details/${kitId}/`),
  ]);

  const findAssetById = (id: number) => {
    const index = _.findIndex(assets, ["id", id]);
    if (index > -1) {
      return assets[index];
    }

    return null;
  };

  const contents = (kitDetail.content || []).map((content) => {
    const asset = findAssetById(content.item);
    return {
      ...content,
      asset,
    };
  });

  return { ...kitDetail, contents };
};
