import React from "react";
import { Outlet } from "react-router-dom";
import AppContainer from "../../componets/AppContainer";

const App = () => {
  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  );
}

export default App;
