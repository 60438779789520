import * as React from "react";
import { Button, Col, Row } from "antd";

type Props = {
  setPin: React.Dispatch<React.SetStateAction<string>>;
  pin: string;
  disabled?: boolean;
  isNumberKeyBoard: boolean;
};

const getAlphabets = () => {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabets = alpha.map((x) => String.fromCharCode(x));
  return alphabets;
};

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

const KeyBoard = ({ setPin, pin, isNumberKeyBoard, disabled }: Props) => {
  return (
    <div className="py-2 w-100" style={{ position: "relative" }}>
      {isNumberKeyBoard ? (
        <Row gutter={4} justify={"center"}>
          {getAlphabets().map((c) => (
            <Col span={4}>
              <Button
                disabled={disabled}
                onClick={(e) => setPin((p) => `${p}${c}`)}
                className="m-2"
                style={{ width: "5rem", height: "4rem" }}
              >
                {c}
              </Button>
            </Col>
          ))}
        </Row>
      ) : (
        <div className=" m-auto px-5" style={{ width: "60%" }}>
          <Row gutter={4} justify={"center"}>
            {numbers.map((n) => {
              return (
                <Col span={8}>
                  <Button
                    disabled={disabled}
                    onClick={(e) => setPin((p) => `${p}${n}`)}
                    className="m-2"
                    style={{ width: "5rem", height: "4rem" }}
                  >
                    {n}
                  </Button>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </div>
  );
};

export default KeyBoard;
