import axios from "axios";
import { getKitContent } from "./checklist";
import * as GetQRCode from "../services/kits/qr";

const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const print = (html: string, callback: () => void) => {
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  const blob = new Blob([html], { type: "text/html" });
  iframe.src = window.URL.createObjectURL(blob);

  iframe.width = "10cm";
  iframe.height = "3cm";
  document.body.appendChild(iframe);
  iframe.focus();

  if (iframe.contentWindow) {
    iframe.contentWindow.print();
  }

  callback();
};

export const printQRCode = async ({
  id,
  callback,
  title,
  batchNumber,
  processDate,
  expiryDate, 
  cycleNumber
}:{
  id: number,
  callback: () => void,
  title: string,
  batchNumber: string,
  processDate: string,
  expiryDate: string, 
  cycleNumber: string
}) => {
  const { data } = await GetQRCode.service(id);
  const qrLink = data.qr;

  const url = `https://cdsimerproxy.metahos.com/${qrLink}`;
  const { data: imageBlob } = await axios.get(url, {
    responseType: "blob",
    headers: {
      "X-Proxy-Target": "https://asset.metahos.com",
    },
  });

  const imageBlobURL = await blobToBase64(imageBlob);

  const html = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>QR Code</title>
      <style>
        html,
        body {
          margin: 0 !important;
          padding: 0 !important;
          width: 13cm !important;
          height: 3cm !important;
        }

        .main-img {
          width: 2.5cm;
          height: 2.5cm;
        }

        body {
        }

        @page {
          padding:0;
          margin:0;
          size: 13cm 3cm !important;
        }
      </style>
    </head>
    <body>
    <page>
        <div style="display: flex; flex-direction: row; justify-content: center; height: 2.6cm; width: 12.5cm; padding: 5px;"> 
            <table>
                <tr>
                    <td>
                        <img class="main-img" src="${imageBlobURL}" />
                    </td>
                    <td colspan="2">
                        <div style="zoom: .7; display: flex; flex-direction: column; padding-right: 20px;">
                            <small><b>Kit Name: </b> ${title}</small>
                            <small><b>Batch No: </b> ${batchNumber}</small>
                            <small><b>Process Date: </b> ${processDate}</small>
                            <small><b>Exp Date: </b> ${expiryDate}</small>
                            <small><b>Cycle No : </b> ${cycleNumber}</small>
                            <small><b>Operator : </b> Abhishek</small>
                        </div>
                    </td>
                </tr>
            </table>
            <table>
                <tr>
                    <td>
                        <img class="main-img" src="${imageBlobURL}" style='padding-left: 20px;' />
                    </td>
                    <td colspan="2">
                        <div style="zoom: .7; display: flex; flex-direction: column;">
                            <small><b>Kit Name: </b> ${title}</small>
                            <small><b>Batch No: </b> ${batchNumber}</small>
                            <small><b>Process Date: </b> ${processDate}</small>
                            <small><b>Exp Date: </b> ${expiryDate}</small>
                            <small><b>Cycle No : </b> ${cycleNumber}</small>
                            <small><b>Operator : </b> Abhishek</small>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </page>
    </body>
  </html>
  `;

  print(html, callback);
};

export const printChecklist = async (
  { kit, outerKit },
  callback: () => void
) => {
  const kitDetail = await getKitContent(kit.id);
  const contents = kitDetail.contents;

  const html = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>QR Code</title>
      <style>
        @page {
          size: A4;
        }
      </style>
    </head>
    <body>
    <h1>${outerKit.title} <span style="color: #444444;">(${
    kitDetail.kit.batch_number
  })</span></h1>
      ${contents
        .filter((content) => !!content?.asset?.name)
        .map(
          (content) =>
            `<div style="padding-left: 10px;color: #222222;"><input type="checkbox" checked />${content.asset.name} (x${content.quantity})</div>`
        )
        .join("")}
    </body>
  </html>
  `;

  print(html, callback);
};
